import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Store from 'store';

import { postIdentityHdyhau, postIdentityHdyhauLegacy } from '@pumpkincare/identity';
import {
  COMPLETE_QUOTE_ID_LS_KEY,
  getMemberRegisterUrl,
  IDENTITY_LOCAL_STORAGE_KEY,
  navigateTo,
  setIsLoggedIn,
} from '@pumpkincare/shared';
import { customSignInToCognito, postUserVet } from '@pumpkincare/user';

import { Paths } from '../app-shell';

export default function useSubmitHdyhau({ vetId: quoteVetId, session } = {}) {
  const history = useHistory();
  const { mvx104McOnboardingModal, mvx218MultiTouchHdyhau, mvx66PumpkinNow } =
    useFlags();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const email = urlSearchParams.get('email');
  const token = urlSearchParams.get('token');

  const identityId = Store.get(IDENTITY_LOCAL_STORAGE_KEY);
  const quoteId = Store.get(COMPLETE_QUOTE_ID_LS_KEY);

  const [isLoading, setIsLoading] = useState(false);

  async function submitHdyhau(input) {
    return mvx218MultiTouchHdyhau
      ? postIdentityHdyhau(identityId, {
          hdyhau: input.map(selection => ({
            quote_id: quoteId,
            is_multi_touch: true,
            session,
            ...selection,
          })),
        })
      : postIdentityHdyhauLegacy(identityId, {
          quote_id: quoteId,
          order: input.order,
          option_identifier: input.hdyhauOptionSelected,
          option_value: input.hdyhauOptionValue,
          vet_id: input.vet?.id,
        });
  }

  async function submit(payload) {
    setIsLoading(true);

    Promise.all([submitHdyhau(payload), customSignInToCognito(email, token)])
      .then(() => {
        const inputVetId = mvx218MultiTouchHdyhau
          ? payload.find(option => option.vetId)?.vetId
          : payload.vet?.id;
        setIsLoggedIn(true);

        if (inputVetId) {
          postUserVet(inputVetId);
        }

        if (mvx66PumpkinNow) {
          history.push(
            Paths.PumpkinNowQuestion +
              '?' +
              new URLSearchParams({ token, session }).toString()
          );
          return;
        }

        mvx104McOnboardingModal || inputVetId || quoteVetId
          ? navigateTo(getMemberRegisterUrl(token))
          : history.push(
              Paths.VetCollection + '?' + new URLSearchParams({ token }).toString()
            );
      })
      .catch(() => {
        if (mvx66PumpkinNow) {
          history.push(
            Paths.PumpkinNowQuestion +
              '?' +
              new URLSearchParams({ token, session }).toString()
          );
          return;
        }
        history.push(Paths.CheckYourEmail);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function submitPumpkinNow(payload) {
    setIsLoading(true);

    return postIdentityHdyhau(identityId, {
      hdyhau: [
        {
          session: urlSearchParams.get('session') || '',
          quote_id: quoteId,
          ...payload,
        },
      ],
    })
      .then(() => {
        navigateTo(getMemberRegisterUrl(token));
      })
      .finally(() => setIsLoading(false));
  }

  return { submit, submitPumpkinNow, isLoading };
}
